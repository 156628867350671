export enum EventName {
  COMMON_VIEW = 'common_view',
  PRODUCT_VIEW = 'item_view',
  SHOP_VIEW = 'designer_view',
  SEARCH = 'search',
  USER_LOGIN = 'user_login',
  USER_REGISTER = 'user_register',
  ADD_TO_CART = 'add_to_cart',
  INITIATE_CHECKOUT = 'initiate_checkout',
}

export enum EventSource {
  ADD_TO_CART = 'ADD_TO_CART',
  BLOG_PAGE = 'BLOG_PAGE',
  BROWSE_ALL_CREATOR_SHOPS = 'BROWSE_ALL_CREATOR_SHOPS',
  BROWSE_CREATORS_BY_ALPHABET = 'BROWSE_CREATORS_BY_ALPHABET',
  BROWSE_CREATORS_TRENDING_CREATORS = 'BROWSE_CREATORS_TRENDING_CREATORS',
  CATALOG_ITEMS = 'CATALOG_ITEMS',
  CLICK_PRINTFUL_BUY_NOW = 'CLICK_PRINTFUL_BUY_NOW',
  COHORT_LANDING_PAGE_LEARN_MORE = 'COHORT_LANDING_PAGE_LEARN_MORE',
  CREATOR_INSTAGRAM_SEARCH = 'CREATOR_INSTAGRAM_SEARCH',
  CREATOR_SHOP = 'CREATOR_SHOP',
  CREATOR_SHOP_PRODUCT_ITEM = 'CREATOR_SHOP_PRODUCT_ITEM',
  CREATOR_JOIN_EMAIL = 'CREATOR_JOIN_EMAIL',
  HOMEPAGE = 'HOMEPAGE',
  HOMEPAGE_PDF = 'HOMEPAGE_PDF',
  MARKETPLACE_HOMEPAGE_BANNER = 'MARKETPLACE_HOMEPAGE_BANNER',
  MARKETPLACE_HOMEPAGE_CONTENT = 'MARKETPLACE_HOMEPAGE_CONTENT',
  MARKETPLACE_HOMEPAGE_FEATURED_IMAGES = 'MARKETPLACE_HOMEPAGE_FEATURED_IMAGES',
  MARKETPLACE_HOMEPAGE_SEARCH_TERM = 'MARKETPLACE_HOMEPAGE_SEARCH_TERM',
  MARKETPLACE_NAV_BAR = 'MARKETPLACE_NAV_BAR',
  MARKETPLACE_POPULAR_PRODUCT_ITEM = 'MARKETPLACE_POPULAR_PRODUCT_ITEM',
  MORE_FROM_THIS_CREATOR = 'MORE_FROM_THIS_CREATOR',
  NEW_AND_TRENDING_FEATURED_CAROUSEL = 'NEW_AND_TRENDING_FEATURED_CAROUSEL',
  NEW_AND_TRENDING_HEADER = 'NEW_AND_TRENDING_HEADER',
  NEW_AND_TRENDING_NEWEST = 'NEW_AND_TRENDING_NEWEST',
  NEW_AND_TRENDING_POPULAR = 'NEW_AND_TRENDING_POPULAR',
  PIETRA_PARTNERS_LANDING_PAGE = 'PIETRA_PARTNERS_LANDING_PAGE',
  PRICING_PAGE = 'PRICING_PAGE',
  PRODUCT_DETAILS_PAGE = 'PRODUCT_DETAILS_PAGE',
  PRODUCT_DETAILS_PAGE_FOOTER = 'PRODUCT_DETAILS_PAGE_FOOTER',
  PRODUCT_GRID_ITEM_SHOP_AVATAR = 'PRODUCT_GRID_ITEM_SHOP_AVATAR',
  RECENTLY_VIEWED_PRODUCTS = 'RECENTLY_VIEWED_PRODUCTS',
  SEARCH_TERMS = 'SEARCH_TERMS',
  SETUP_COMMERCE = 'SETUP_COMMERCE',
  SHOP_ALL_FEATURED = 'SHOP_ALL_FEATURED',
  SHOP_ALL_FILTERS = 'SHOP_ALL_FILTERS',
  SHOP_ALL_POPULAR_PRODUCT = 'SHOP_ALL_POPULAR_PRODUCT',
  SHOP_ALL_PRODUCT_GRID_ITEM = 'SHOP_ALL_PRODUCT_GRID_ITEM',
  WEBSITE_NAV_BAR = 'MARKETPLACE_NAV_BAR',
  MARKETPLACE_AI_STOREFRONT = 'MARKETPLACE_AI_STOREFRONT',
}
