import React, { useEffect, useMemo } from 'react';
import Layout from '@/layouts';
import { Profile } from '@/components/Designers';
import { GetServerSidePropsContext, InferGetStaticPropsType } from 'next';
import { HeaderTag, MetaInfo, SEO_META_PAGES } from '@/shared/types/seo';
import { NextPageWithLayout } from '@/pages/_app';
import { SEO_META } from '@/shared/seo/seo';
import { formatPrice, imgix } from '@/shared/utils/utils';
import { getItemMinPrice } from '@/shared/utils/store-helper';
import { getStoreDetailsWithHandle, getAllJewelry, sendMoreCommonEvent } from '@/lib/service';
import { isEmpty, get } from 'lodash';
import { useConfigProvider } from '@/lib/context';
import type { IProduct } from '@/shared/types/product-types.interface';
import type { Jewelry } from '@/shared/types/jewelry.interface';
import './index.less';
import { EventSource } from '@/shared/types/event-types';

export async function getServerSideProps(context: GetServerSidePropsContext<{ handle: string }>) {
  try {
    const storeDetails = await getStoreDetailsWithHandle(context.params.handle || '', {
      includeMediaUrls: true,
    });

    let products: Jewelry[] = [];

    if (storeDetails?.id) {
      const data = await getAllJewelry({
        storeId: storeDetails.id,
        sorts: '-sortWeight,-globalSort',
        category: '',
        material: '',
        minPriceRanges: [],
        page: 0,
        size: 400,
        isFetchStoreList: false,
        excludePrintful: false,
      });
      products = data.list || [];
    }
    return {
      props: { storeDetails, products },
    };
  } catch (e) {
    return { notFound: true };
  }
}

const DesignerFullProfile: NextPageWithLayout<
  InferGetStaticPropsType<typeof getServerSideProps>
> = ({ products, storeDetails }) => {
  const { isMobile } = useConfigProvider();

  useEffect(() => {
    if (storeDetails?.id) {
      sendMoreCommonEvent(EventSource.MARKETPLACE_AI_STOREFRONT, {
        commonContent: 'init-storefront-page',
        field1: storeDetails.id,
      });
    }
  }, [storeDetails.id]);

  return useMemo(() => {
    return (
      <Profile
        className="id"
        store={storeDetails}
        products={products as IProduct[]}
        isMobile={isMobile}
      />
    );
  }, [isMobile, products, storeDetails]);
};

DesignerFullProfile.getLayout = (page) => {
  const { storeDetails, products } = page.props;

  const getProfileDescription = (): string => {
    return !isEmpty(storeDetails.ourStory)
      ? storeDetails.ourStory
      : `Support ${storeDetails?.storeFrontTitle}'s shop on Pietra!`;
  };

  const title = `${storeDetails?.storeFrontTitle} | Pietra`;

  const head = SEO_META[SEO_META_PAGES.MARKETPLACE_CREATOR_SHOP].head({
    title: title,
    description: getProfileDescription(),
    url: typeof window === 'object' ? window.location.href : '',
    image: imgix(get(storeDetails, 'storeFrontAvatarUrl'), { h: 300, w: 300, q: 100 }),
  });

  const getProductMinPrice = (item: IProduct) => {
    const price = getItemMinPrice(item);
    if (price > 0) {
      return formatPrice(Number.parseFloat(price).toFixed(2));
    }
    return '';
  };

  const layoutHeaders = SEO_META[SEO_META_PAGES.MARKETPLACE_CREATOR_SHOP].headers([
    { tag: HeaderTag.H1, text: title },
    { tag: HeaderTag.H2, text: 'Products' },
    ...products.map((product: IProduct) => {
      return {
        tag: HeaderTag.H3,
        text: `${product.name} (${getProductMinPrice(product)})`,
      };
    }),
  ]);

  const layoutStructuredData = SEO_META[SEO_META_PAGES.MARKETPLACE_CREATOR_SHOP].jsonld({
    store: storeDetails || {},
    url: typeof window === 'object' ? window.location.href : '',
  }) as any;

  return (
    <Layout
      store={storeDetails}
      headers={layoutHeaders}
      head={head as MetaInfo}
      structured={layoutStructuredData}
      hideFooter={true}
      hideHeader={true}
    >
      {page}
    </Layout>
  );
};

export default DesignerFullProfile;
