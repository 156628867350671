import './index.less';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
const TopBanner = ({
  bannerText,
  color,
  textColor,
  onClose,
}: {
  bannerText: string;
  color: string;
  textColor: string;
  onClose?: () => void;
}) => {
  return (
    <div style={{ backgroundColor: color }} className="top-banner utility-small">
      <span style={{ color: textColor ? textColor : '#000' }}>{bannerText}</span>
      {onClose && (
        <Button
          className="close-btn"
          type={'text'}
          icon={<CloseOutlined />}
          onClick={onClose}
        ></Button>
      )}
    </div>
  );
};

export default TopBanner;
