import React, { useMemo, useState } from 'react';
import Image from 'next/image';
import { Carousel, CarouselProps, Image as AntdImage } from 'antd';
import { getStorePortfolio } from '@/shared/utils/store-helper';
import type { Store } from '@/shared/types/store.interface';
import { imgix, isVideo } from '@/shared/utils/utils';
import { useWindowSize } from 'react-use';
import { isEmpty } from 'lodash';
import './index.less';

const Gallery = ({
  store,
  onDisableGallery,
  isMobile,
  showGallery,
}: {
  store: Store;
  showGallery: boolean;
  onDisableGallery?: () => void;
  isMobile?: boolean;
}) => {
  const { width } = useWindowSize();
  const [visible, setVisible] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(0);
  const SamplePrevArrow = ({ slideCount, currentSlide, ...props }: any) => (
    <div {...props} data-slide-count={slideCount} data-current-slide={currentSlide}>
      <Image src="/images/white-left.svg" alt="prevArrow" width={20} height={20} />
    </div>
  );

  const SampleNextArrow = ({ slideCount, currentSlide, ...props }: any) => (
    <div {...props} data-slide-count={slideCount} data-current-slide={currentSlide}>
      <Image src="/images/white-right.svg" alt="prevArrow" width={20} height={20} />
    </div>
  );

  const photos = useMemo((): string[] => {
    const _photos: string[] = [];
    _photos.push.apply(_photos, getStorePortfolio(store));
    const pics = _photos.filter((url: string) => !isVideo(url));
    if (isEmpty(pics)) {
      onDisableGallery?.();
      return [];
    }
    return pics;
  }, [onDisableGallery, store]);

  const getSlidesToShow = useMemo((): number => {
    if (photos.length >= 5) {
      if (width > 1280) {
        return 5;
      }
      if (width > 980 && width <= 1280) {
        return 4;
      }
      if (width > 768 && width <= 980) {
        return 3;
      }
      if (width > 600 && width <= 768) {
        return 2;
      }
      return 1;
    }
    return photos.length;
  }, [photos.length, width]);

  const carouselSettings = useMemo(
    (): CarouselProps => ({
      dots: false,
      infinite: true,
      accessibility: true,
      arrows: !isMobile,
      autoplay: true,
      speed: 500,
      centerPadding: '24px',
      slidesToShow: getSlidesToShow,
      adaptiveHeight: true,
      slidesToScroll: 1,
      className: 'gallery-carousel',
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    }),
    [getSlidesToShow, isMobile],
  );

  if (isEmpty(photos) && !showGallery) {
    return (
      <div className="designer-gallery">
        <Image src={'/images/designer-profile-banner.png'} alt="" fill />
      </div>
    );
  }

  return (
    <div className="designer-gallery">
      <AntdImage.PreviewGroup
        items={photos}
        preview={{
          visible: false,
          onVisibleChange: () => {
            setVisible(false);
          },
          onChange: (_current) => {
            setCurrent(_current);
          },
          current,
        }}
      >
        <Carousel {...carouselSettings}>
          {photos.map((photo, index) => (
            <div
              key={`photo-item-${index}`}
              className="slide"
              onClick={() => {
                setVisible(true);
                setCurrent(index);
              }}
            >
              <Image alt="photo" src={imgix(photo, { h: 250, dpr: 2, q: 100 })} fill />
            </div>
          ))}
        </Carousel>
      </AntdImage.PreviewGroup>
    </div>
  );
};

export default Gallery;
