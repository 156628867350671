import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { ImgWithFallback, EmailSignup } from '@/components/Common';
import { Gallery, Products } from '@/components/Designers';
import { PIETRA_LOGO_STONE } from '@/shared/constants/app';
import { TopBanner } from '@/components/Shop';
import { Button } from 'antd';
import { isStorePasswordProtected } from '@/shared/utils/store-helper';
import { getTagColor } from '@/shared/utils/marketplace';
import { isEmpty } from 'lodash';
import { useToggle } from 'react-use';
import type { IProduct } from '@/shared/types/product-types.interface';
import type { Store } from '@/shared/types/store.interface';
import getConfig from 'next/config';
import Link from 'next/link';
import Image from 'next/image';
import { hasUnlockedPlatform } from '@/shared/utils/utils';
import cN from 'classnames';
import { ArrowRightOutlined, CloseOutlined } from '@ant-design/icons';
import './index.less';

const PageTopBanner = () => {
  const { publicRuntimeConfig } = getConfig();
  const [closeBanner, setCloseBanner] = useState<boolean>(false);
  useEffect(() => {
    if (!closeBanner && typeof window !== 'undefined') {
      window.scroll({ top: 0, behavior: 'smooth' });
    }
  }, [closeBanner]);

  return (
    <div className={cN('page-top-banner-wrap', { hide: closeBanner })}>
      <div className="page-top-banner-content">
        <Image src={'/images/icons/flash.svg'} alt="flash" width={20} height={20} />
        <div className="page-top-banner-content-inner">
          <span className="text">
            See how Pietra is lowering the barrier to entry to starting your own business.
          </span>
          <a
            className="more-btn"
            href={`${publicRuntimeConfig.NEXT_WEBSITE_BASE_URL}/ecommerceforall`}
            target="_blank"
          >
            <ArrowRightOutlined />
            Learn more
          </a>
        </div>
      </div>
      <Button
        className="close-btn"
        type={'text'}
        icon={<CloseOutlined />}
        onClick={() => {
          console.log('close');
          setCloseBanner(true);
        }}
      ></Button>
    </div>
  );
};

const DesignerProfile = ({
  className,
  store,
  isMobile,
  products,
}: {
  className?: string;
  store: Store;
  products: IProduct[];
  isMobile: boolean;
}) => {
  const { publicRuntimeConfig } = getConfig();
  const [showEmailCapture, showEmailCaptureFunc] = useToggle(false);
  const [showGallery, setShowGallery] = useState<boolean>(true);
  const [lineClamp, setLineClamp] = useState<number>(6);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [playBtnIcon, setPlayBtnIcon] = useState<string>('/images/icons/play-arrow-icon.svg');
  const [play, setPlay] = useState<boolean>(false);

  const hasBannerText = useMemo(() => {
    return !isEmpty(store.bannerText);
  }, [store.bannerText]);

  const designerTags = useMemo(() => store?.tags ?? [], [store?.tags]);

  const handlePlay = useCallback(() => {
    videoRef.current
      ?.play()
      .then(() => {
        videoRef.current.muted = false;
        setPlay(true);
        setPlayBtnIcon('/images/icons/pause-icon.svg');
      })
      .catch((e) => {
        console.log('play error:', e);
      });
  }, []);

  const handlePause = useCallback(() => {
    videoRef.current?.pause();
    setPlay(false);
    setPlayBtnIcon('/images/icons/play-arrow-icon.svg');
  }, []);

  return (
    <>
      {!isEmpty(store.aiStoreFrontGenerate) && <PageTopBanner />}
      <div className={classNames('designer-full-profile', className)}>
        {hasBannerText && (
          <TopBanner
            bannerText={store.bannerText}
            color={'rgba(0, 0, 0, 0.60)'}
            textColor={'#fff'}
          />
        )}

        <div className="gallery">
          <Gallery
            showGallery={!store.isStorefrontProtected && showGallery}
            store={store}
            isMobile={isMobile}
            onDisableGallery={() => setShowGallery(false)}
          />
        </div>
        <div className="content">
          <div className="designer-information">
            <div className="designer-primary-information">
              <div
                className={cN('avatar-container', { mobile: isMobile, play })}
                onMouseEnter={() => {
                  if (!isMobile) {
                    handlePlay();
                  }
                }}
                onMouseLeave={() => {
                  if (!isMobile) {
                    handlePause();
                  }
                }}
              >
                <ImgWithFallback
                  className="avatar"
                  src={store.storeFrontAvatarUrl ?? PIETRA_LOGO_STONE}
                  alt="avatar"
                  width={300}
                  height={300}
                />
                <div className={cN('avatar-video-wrap', { mobile: isMobile })}>
                  <video
                    muted
                    controls={false}
                    controlsList="nodownload"
                    x-webkit-airplay="allow"
                    playsInline
                    webkit-playsinline
                    ref={videoRef}
                    width="100%"
                    height="100%"
                    loop={false}
                    preload={'metadata'}
                    className="avatar-video"
                    src={store?.video}
                    poster={store.storeFrontAvatarUrl ?? PIETRA_LOGO_STONE}
                    onEnded={handlePause}
                  ></video>
                </div>
                {!isEmpty(store?.video) && (
                  <Button
                    onClick={() => {
                      if (videoRef.current.paused) {
                        handlePlay();
                      } else {
                        handlePause();
                      }
                    }}
                    className="video-play-btn"
                    type="text"
                    shape="circle"
                    icon={<Image src={playBtnIcon} width={12} height={12} alt={'volume'} />}
                  />
                )}
              </div>
              <div className="info-container">
                <div className="inline">
                  <span className="name">{store.storeFrontTitle || store.title || '--'}</span>
                </div>
                <div
                  className="description"
                  onClick={() => {
                    setLineClamp((v) => (v === 6 ? 100 : 6));
                  }}
                  style={{ '--line-clamp': lineClamp } as any}
                >
                  {store.ourStory || ''}
                </div>

                {!isStorePasswordProtected(store) && (
                  <div className="buttons-and-tags">
                    {designerTags.map((tag, index) => (
                      <span
                        key={`tag-item-${index}`}
                        className="tag"
                        style={{ backgroundColor: getTagColor(tag) }}
                      >
                        {tag}
                      </span>
                    ))}
                    <span
                      className="action-button"
                      style={{ display: 'none' }}
                      onClick={showEmailCaptureFunc}
                    >
                      Join email list
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Products products={products} isMobile={isMobile} />
        </div>
        <div className="footer">
          {!hasUnlockedPlatform(store) && (
            <>
              <span>Powered By </span>
              <Link href={`${publicRuntimeConfig?.NEXT_CREATOR_HUB ?? '/'}`}>Pietra</Link>
            </>
          )}
        </div>
        <EmailSignup
          open={showEmailCapture}
          storeId={store.id}
          className="designer-subscribe"
          signupMeta={{
            title: [{ text: `Never miss an update from ${store.storeFrontTitle}`, style: {} }],
            subtitle: `You'll get notified when ${store.storeFrontTitle} releases new collections and has shop promotions.`,
            backgroundColor: '#fff',
            imageUrl:
              store.storeFrontAvatarUrl ||
              'https://static.pietrastudio.com/public/file_uploads/8d5f6084a543f497e9bcc6b035c0b3f4.png',
            imageAlt: `Sign up for ${store.storeFrontTitle}'s email list.`,
            imageBorder: true,
            signupConfirmationText:
              'Welcome to ' + store.storeFrontTitle + "'s community. We'll be in touch!",
          }}
          onCancel={showEmailCaptureFunc}
        />
      </div>
    </>
  );
};
export default DesignerProfile;
